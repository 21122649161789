@import 'mantine-scss-mixins';
.errorRejectedValue {
  //color: light-dark(var(--mantine-color-gray-9),var(--mantine-color-dark-0)); // todo: will this work ever?test later, it's cleaner
  @include dark {
    color: var(--mantine-color-dark-0);
  }
  @include light {
    color: var(--mantine-color-gray-9);
  }
  padding: 0 1em;
  font-weight: 600;
}

.errorIndicator {
  position: fixed;
  cursor: pointer;
  z-index: 500;
  top: 3.5rem;
  right: 2rem;
  color: red;
}

.errorContainer {
  @include dark {
    background-color: var(--mantine-color-dark-7);
  }
  @include light {
    background-color: var(--mantine-color-gray-0);
  }
  position: fixed;
  top: 7em;
  right: 0;
  z-index: 500;
  padding-bottom: 1em;
  border: 1px solid #999;
  border-radius: 3px;
  width: fit-content;
  max-width: 80vw;
  max-height: 40%;
  overflow: auto;
  height: fit-content;
  margin: 10px;
}
