@import 'mantine-scss-mixins';
.tableOfContentsLink {
  cursor: pointer;
  &:hover {
    & > div {
      display: block;
    }
  }
  @include dark {
    color: var(--mantine-color-blue-6);
    &:hover {
      color: var(--mantine-color-blue-8);
    }
  }
  @include light {
    color: var(--mantine-color-blue-9);
    &:hover {
      color: var(--mantine-color-blue-6);
    }
  }

  display: flex;
  justify-content: space-between;
  padding-right: 2em;
  width: 100%;
}

.tableOfContestsFocusButton {
  display: none;
  padding: 0 0.5rem;

  &:hover {
    @include dark {
      color: var(--mantine-color-blue-1);
      background-color: var(--mantine-color-blue-9);
    }
  }

  @include light {
    color: var(--mantine-color-blue-6);
    background-color: var(--mantine-color-blue-1);
  }
}
