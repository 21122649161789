@import 'mantine-scss-mixins';
.button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.menuControl {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  @include dark {
    background-color: var(--mantine-color-dark-6);
    border-left: 1px solid var(--mantine-color-dark-7);
    &:hover:not([disabled]),
    &:active:not([disabled]) {
      background-color: var(--luma-dark-primary-btn-hover);
    }
    &:disabled {
      outline: 1px solid var(--mantine-color-gray-5);
    }
  }

  @include light {
    background-color: var(--luma-blue-medium);
    border-left: 1px solid white;
    &:hover:not([disabled]),
    &:active:not([disabled]) {
      background-color: var(--luma-primary80Daintree);
    }
    &:disabled {
      outline: 1px solid var(--luma-blue-medium);
    }
  }
  outline: none;

  &:disabled {
    background-color: var(--mantine-color-dark-4);
    border-color: var(--mantine-color-dark-4);
    opacity: 0.7;
  }
}
