@import 'mantine-scss-mixins';
.container {
  @include dark {
    background-color: var(--mantine-color-dark-5);
  }
  @include light {
    background-color: var(--mantine-color-gray-1);
  }
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0 1.5rem 1.5rem;
  justify-content: space-between;
  gap: 1rem;
  width: 28rem;
  position: sticky;
  top: calc(var(--global-header-height) + var(--entry-and-edit-header-height));
  height: calc(
    100dvh - calc(
        (var(--global-header-height) + var(--entry-and-edit-header-height))
      )
  );
  z-index: 20;
}

.containerScrollSection {
  overflow: auto;
  scrollbar-gutter: stable;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.primaryFooterButtons {
  display: flex;
  flex-flow: wrap;
  gap: 1rem;
}

.submitSection {
  display: flex;
  gap: 1rem;
  padding-top: 2rem;
  border-top: 1px solid black;
  width: 100%;
  justify-content: flex-end;
  padding-right: 1.5rem;
}

.buttonSection {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
}
