@import 'mantine-scss-mixins';

.primary-button {
  color: white;
  width: fit-content;
  @include dark {
    background-color: var(--luma-primary70);
    border-color: var(--luma-primary70);
  }

  @include light {
    background-color: var(--luma-blue-medium);
    border-color: var(--luma-blue-medium);
  }

  &:active:not([disabled]),
  &:focus:not([disabled]),
  &:hover:not([disabled]) {
    @include dark {
      background-color: var(--luma-primary80-daintree);
    }
    @include light {
      background-color: var(--luma-primary60);
    }
  }

  &:disabled {
    background-color: var(--mantine-color-dark-4);
  }
}

.secondary-button {
  @include dark {
    color: white;
    background-color: var(--luma-dark-secondary-btn);
    border-color: var(--luma-hover-grey);
    &:active:not([disabled]),
    &:hover:not([disabled]) {
      background-color: var(--luma-dark-secondary-btn-hover);
    }
  }
  @include light {
    color: var(--luma-blue-medium);
    background-color: white;
    border-color: var(--luma-blue-medium);
    &:active:not([disabled]),
    &:hover:not([disabled]) {
      background-color: var(--luma-hover-grey);
    }
  }
}

.root {
  &[data-variant='primary'] {
    @extend .primary-button;
  }

  &[data-variant='secondary'] {
    @extend .secondary-button;
  }

  &[data-variant='secondary-small'] {
    @extend .secondary-button;
    padding: 0.5em;
  }

  &[data-variant='primary-small'] {
    @extend .primary-button;
    padding: 0.25rem 1rem;
  }

  &[data-variant='primary-full'] {
    @extend .primary-button;
    justify-content: center;
    width: 100%;
  }
}
