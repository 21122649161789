@import 'mantine-scss-mixins';
.root {
  padding-top: 80px;
  padding-bottom: 80px;
}

.inner {
  position: relative;
}

.image {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 0;
  opacity: 0.75;
}

.content {
  padding-top: 220px;
  position: relative;
  z-index: 1;

  @include smaller-than($mantine-breakpoint-sm) {
    padding-top: 120px;
  }
}

.title {
  text-align: center;
  font-weight: 900;
  font-size: 38px;
  @include smaller-than($mantine-breakpoint-sm) {
    font-size: 32px;
  }
}

.description {
  max-width: 540px;
  margin: auto;
  margin-top: var(--mantine-spacing-xl);
  margin-bottom: calc(var(--mantine-spacing-xl) * 1.5);
}
