@font-face {
  font-family: 'IBMPlexSansLight';
  src:
    url('IBMPlexSans-Light.woff2') format('woff2'),
    url('IBMPlexSans-Light.woff') format('woff'),
    url('IBMPlexSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: 'IBMPlexSansRegular';
  src:
    url('IBMPlexSans-Regular.woff2') format('woff2'),
    url('IBMPlexSans-Regular.woff') format('woff'),
    url('IBMPlexSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: 'IBMPlexSansMedium';
  src:
    url('IBMPlexSans-Medium.woff2') format('woff2'),
    url('IBMPlexSans-Medium.woff') format('woff'),
    url('IBMPlexSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: 'IBMPlexSansSemiBold';
  src:
    url('IBMPlexSans-SemiBold.woff2') format('woff2'),
    url('IBMPlexSans-SemiBold.woff') format('woff'),
    url('IBMPlexSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: 'IBMPlexSansBold';
  src:
    url('IBMPlexSans-Bold.woff2') format('woff2'),
    url('IBMPlexSans-Bold.woff') format('woff'),
    url('IBMPlexSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
}
