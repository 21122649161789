@import 'mantine-scss-mixins';
.documentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.documentRow {
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    @include dark {
      background-color: var(--mantine-color-dark-6);
    }
    @include light {
      background-color: var(--mantine-color-gray-0);
    }
  }

  gap: 1rem;
  padding: 0.5rem;
}
