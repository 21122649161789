:root {
  --global-header-height: 50px;
  --entry-and-edit-header-height: 53px;
  --luma-light-theme-entry-sections: #c7dee6de;
  --luma-dark-theme-addition: #0b680b;
  --luma-dark-theme-deletion: #701d1d;
  --luma-dark-theme-new-value: #585e3b;
  --luma-light-theme-addition: #b3ec99;
  --luma-light-theme-deletion: #ffb7b7;
  --luma-light-theme-new-value: #c3c36b;
  --text-grey: #333538;
  --luma-dark-theme-modification: rgb(72 65 28 / 61%);
  --luma-light-theme-modification: rgb(217 217 148 / 60%);
  --luma-dark-primary-btn-hover: #343b5e;
  --luma-secondary-primary-btn-hover: #64615d;
  --luma-dark-secondary-btn-hover: #64615d;
  --luma-hover-grey: #dadada;
  --luma-dark-secondary-btn: #8e8d8c;
  --luma-positive60: #65a343;
  --luma-primary-green: #4c645e;
  --luma-primary10: #e0f4ff;
  --luma-primary20: #a0dcf8;
  --luma-primary30: #61c0ed;
  --luma-primary40: #26a0dc;
  --luma-primary50: #007dc2;
  --luma-primary60: #02629d;
  --luma-primary70: #004570;
  --luma-primary75: #292929;
  --luma-primary80Daintree: #00263e;
  --luma-primaryGreenLight: #c3dbc7;
  --luma-blue-medium: #003252;
}
