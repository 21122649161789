.wrapFormFields {
  display: flex;
  gap: 2em;
  flex-wrap: wrap;
}

.small-field-section {
  width: 80%;
}

.ranges-button {
  margin-top: 0.5em;
}

.basic-button {
  color: white;
  background-color: #192a36;
  width: fit-content;
  min-width: 10em;
}

.basic-secondary-button {
  background-color: white;
  color: #192a36;
  border: 1px solid #192a36;
  width: fit-content;
  min-width: 10em;
}

.form-section {
  container-type: inline-size;
  display: flex;
  gap: 5%;
  justify-content: flex-start;
  width: 90%;
  margin: auto;
  flex-flow: row wrap;
}

.form-subsection {
  width: 108%;
  display: flex;
  flex-direction: column;
  row-gap: 0.8em;
}

.array-entry-section-container {
  container-type: inline-size;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  margin-bottom: 1em;
}

.array-entry-section {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

/* Small */
@container (min-width: 30em) {
  .form-subsection {
    width: 45%;
  }

  .array-entry-section,
  .array-entry-section-container {
    flex-flow: row wrap;
    align-items: flex-end;
  }
}

/* Medium */
@container (min-width: 48em) {
  .form-subsection {
    width: 30%;
    max-width: 16em;
  }
}

/*.mantine-InputWrapper-label {*/
/*  position: absolute;*/
/*  z-index: 1;*/
/*  left: 10px;*/
/*  font-size: .8em;*/
/*}*/

/*.mantine-InputWrapper-root {*/
/*  position: relative;*/
/*}*/

/*.mantine-Input-input {*/
/*  &:not(.mantine-MultiSelect-input) {*/

/*}*/
/*  padding-top:2em;*/
/*  padding-bottom: 1em;*/
/*}*/
/*.mantine-MultiSelect-input {*/
/*  color: blue;*/
/*  background-color: green;*/
/*  width: 10%;*/
/*}*/

.react-json-scale-svg {
  & svg {
    margin: 0.5rem 0.75rem 0 0.75rem;
    transform: scale(1.5);
  }

  & .icon-container svg {
    margin: 0;
    transform: scale(1);
  }
}
