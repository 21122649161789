@import 'mantine-scss-mixins';
@import 'src/Styles/breakpoints';

.headerRoot {
  height: var(--global-header-height);
  position: sticky;
  z-index: 50;
}

.userName {
  font-weight: 500;
  margin-top: auto;
  margin-right: 0.5em;
}

.dropdown {
  position: absolute;
  top: var(--global-header-height);
  left: 0;
  right: 0;
  z-index: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-top-width: 0;
  overflow: hidden;
  @media screen and (min-width: $mantine-breakpoint-md) {
    display: none;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  max-width: 100%;
}

.links {
  display: flex;
  gap: 1rem;
  align-items: center;
  @media screen and (max-width: $mantine-breakpoint-md) {
    display: none;
  }
}

.burger {
  @media screen and (min-width: $mantine-breakpoint-md) {
    display: none;
  }
}

.link {
  display: block;
  line-height: 1;
  padding: 8px 12px;
  border-radius: var(--mantine-radius-sm);
  text-decoration: none;
  @include dark {
    color: var(--mantine-color-dark-0);
  }
  @include light {
    color: var(--mantine-color-gray-7);
  }
  font-size: var(--mantine-font-size-sm);
  font-weight: 500;

  &:hover {
    @include dark {
      background-color: var(--mantine-color-dark-6);
    }
    @include light {
      background-color: var(--mantine-color-gray-0);
    }
  }

  @include smaller-than($mantine-breakpoint-sm) {
    border-radius: 0;
    padding: var(--mantine-spacing-md);
  }
}

.linkInner {
  @extend .link;
  padding: 0;
}

.linkActive {
  @include dark {
    background-color: var(--mantine-color-blue-9) !important;
    color: white !important;
  }

  @include light {
    background-color: var(--mantine-color-blue-6) !important;
    color: white !important;
  }
  //&:hover {

  //}
}
