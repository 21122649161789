@import 'App';
@import 'mantine-theme-variables';
@import '../fonts/IBMPlexSans/LumaFonts.css';
@import 'mantine-overrides.scss';

body {
  margin: 0;
  font-family: 'IBM Plex Sans', sans-serif;
  //font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mantine-AppShell-main {
  padding: 0px;
}

html {
  @media screen and (min-width: 600px) {
    font-size: 14px;
  }
  @media screen and (min-width: 1000px) {
    font-size: 16px;
  }
}

//.react-datepicker__input-container input {
//  width: 100%;
//  padding: 0.5em;
//  border-radius: 4px;
//  font-size: .9em;
//  border: 1px solid #ced4da;
//}

.react-json-view .validation-failure {
  position: sticky;
  top: 8rem;
}
