@import 'mantine-scss-mixins';
.formSectionHeader {
  padding: 0.4em min(5%, 2em);
  font-size: 1.2em;
  border-radius: 4px;
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: 1em;
  justify-content: space-between;
  position: sticky;
  z-index: 4;
  top: calc(
    var(--global-header-height) + var(--entry-and-edit-header-height)
  ); // global nav + edit mode banner
  @include dark {
    color: white;
  }
  @include light {
    color: var(--text-grey);
  }
}

.formSectionHeaderEditMode {
  @include dark {
    background-color: var(--luma-primary-green);
  }
  @include light {
    background-color: var(--luma-primaryGreenLight);
  }
}

.formSectionHeaderNonEditMode {
  @include dark {
    background-color: var(--mantine-color-dark-3);
  }
  @include light {
    background-color: var(--luma-light-theme-entry-sections);
  }
}

.formHeaderWithValues {
  @include dark {
    border-bottom: 4px solid var(--luma-primary50);
  }
  @include light {
    border-bottom: 4px solid var(--luma-primary40);
  }
}

.formHeaderWithValuesEditMode {
  border-bottom: 4px solid var(--luma-positive60);
}

.dragHandle {
  //...theme.fn.focusStyles(),
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: var(--mantine-color-gray-6);
  @include dark {
    color: var(--mantine-color-dark-1);
  }
  padding-left: var(--mantine-spacing-md);
  padding-right: var(--mantine-spacing-md);
}

.itemDragging {
  box-shadow: var(--mantine-shadow-md);
  opacity: 0.8;
}
