@import 'mantine-scss-mixins';
.actionHeader {
  position: sticky;
  top: 3em;
  display: flex;
  justify-content: space-between;
  background-color: white;
  @include dark {
    background-color: var(--mantine-colors-dark-7);
  }
  padding: 1em;
  z-index: 1001;
}
