@import 'mantine-scss-mixins';
.newDateSection {
  z-index: 5;
  @include dark {
    background-color: var(--mantine-color-dark-7);
  }
  @include light {
    background-color: white;
  }
  padding: 1em;
  border-bottom: 1px solid;
  margin-bottom: 1em;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
}

.fixedInput {
  width: 12rem;
}

.fixedInputSmall {
  width: 8rem;
}
