@import 'mantine-scss-mixins';
.productSearchContainer {
  display: flex;
  flex-shrink: 0;
  flex-flow: row;
  gap: 2em;
  margin: 0.5em 1em;
  align-items: flex-end;
}

.entryAndEditHeader {
  height: var(--entry-and-edit-header-height);
  z-index: 20;
  display: flex;
  position: sticky;
  top: var(--global-header-height);
  @include dark {
    background-color: var(--mantine-color-dark-5);
  }
  @include light {
    background-color: var(--mantine-color-gray-1);
  }
}

.title {
  display: flex;
  margin: 0 1em;
  flex-shrink: 0;
  align-items: center;
}
